import type { NavItem } from "~/components/site/nav";
import { Nav } from "~/components/site/nav";

const navigation: NavItem[] = [
  { name: "Careers", href: "/careers" },
  { name: "Security", href: "/security" },
  { name: "Legal", href: "/legal/terms" },
];

export function Footer(): JSX.Element {
  return (
    <footer className="text-sm text-gray-500 dark:text-gray-400">
      <div className="container py-6 flex flex-col sm:flex-row items-center justify-between gap-y-6">
        <p className="order-2 sm:order-1 text-center">
          © {new Date().getFullYear()} Brale Inc. NMLS ID #2376957
        </p>
        <div className="order-1 sm:order-2 flex flex-wrap justify-center space-x-6 gap-y-2 print:hidden">
          {navigation.map((n) => (
            <Nav className="nav hover" key={n.name} item={n} />
          ))}
        </div>
      </div>
    </footer>
  );
}
